import React from 'react'
import NavBar from '../Components/NavBar'
import Footer from '../Components/Footer'
import image1 from '../Images/downImg3.png'
import image2 from '../Images/shape-033.png'
import image3 from '../Images/shape-05.png'
import image4 from '../Images/downImg1.png'
import Accordion from 'react-bootstrap/Accordion';


function Career() {
  return (
    <div>
      <div className='mainCareer'>
        <NavBar/>
      </div>

      <div className='mainContentCareer'>

        <div className='careerLeft'>
          <img src={image1} className='careerImage1' alt="" />
          <img src={image2} className='careerImage2' alt="" />
          <div className='carrLefDiv1'></div>
          <div className='carrLefDiv2'></div>
          <div className='carrLefDiv3'></div>
          <div className='carrLefDiv4'></div>
          <img src={image3} alt="" className='careerImage3'/>
          <img src={image4} alt="" className='careerImage4'/>
        </div>

        <div className='careerRight'>

          <h6>CAREER</h6>
          <h1>Join our Team</h1>
          <p>Linkafrex is proud to be an Equal Opportunity/Affirmative Action employer. All qualified applicants will receive consideration for employment without regard to sex, gender identity, sexual orientation, race, color, religion, national origin, disability, protected Veteran status, age, or any other characteristic protected by law.</p>

            <br />
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Customer Care Executive</Accordion.Header>
              <Accordion.Body>
              Customer Care Job Description: <br />
              Responsible for delivering exceptional customer service, addressing inquiries, resolving issues, and ensuring customer satisfaction through effective communication and problem-solving skills.
              <br /> <br />
              Qualifications: <br />
              Strong interpersonal skills, excellent communication abilities, and a customer-centric mindset, with the ability to empathize, remain patient, and handle customer inquiries and concerns professionally.

              <br /> <br />
              If you think you qualify for this job send us an application to <a href="mailto:info@linkafrex.com ">info@linkafrex.com </a> 
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Sales Representative</Accordion.Header>
              <Accordion.Body>
              
              🤝 Build Partnerships: Forge strong connections with businesses in Nairobi and beyond.
              <br /> 
              📈 Drive Growth: Be a driving force behind Linkafrex's expansion and success.
              <br /> 
              🎯 Hit Targets: Set and achieve sales targets, shaping the growth story of our company.
              <br /> <br />
              If you think you qualify for this job send us an application to <a href="mailto:info@linkafrex.com">info@linkafrex.com </a> 
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="2">
              <Accordion.Header>Motorbike Riders</Accordion.Header>
              <Accordion.Body>
              Rider Job Description: <br /> 
              Responsible for timely and efficient deliveries, maintaining a high level of professionalism, ensuring the safety of items in transit, and providing excellent customer service during the delivery process.
              <br /> <br />
              Rider Qualifications: <br /> 
              Valid driver's license, knowledge of local routes and traffic regulations, good time management skills, strong sense of responsibility, and a customer-friendly attitude.

              <br /> <br />
              If you think you qualify for this job send us an application to <a href="mailto:info@linkafrex.com ">info@linkafrex.com </a> 
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

        </div>

      </div>
      <Footer/>
    </div>
  )
}

export default Career
import './App.css';
import './Responsive.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Routes, Route, BrowserRouter } from "react-router-dom";

import Home from './Pages/Home';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import Datadeletion from './Pages/Datadeletion';
import Career from './Pages/Career';
import Download from './Pages/Download';


function App() {
  return (
    <div className="App">
      <BrowserRouter>
          <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/download" element={<Download />} />
              <Route path="/" element={<Home />} />
              <Route path="/data-deletion" element={<Datadeletion />} />
              <Route path="/career" element={<Career />} />
          </Routes>
      </BrowserRouter> 
    </div>
  );
}

export default App;

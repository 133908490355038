import React from 'react'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

function Datadeletion() {
  return (
    <div>
        
        <h1>Request For your Data to be deleted</h1>

    <Form>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label> Enter your phone Number</Form.Label>
        <Form.Control type="email" placeholder="Phone number" />
      </Form.Group>

    
      <Button variant="primary" type="submit">
        Submit
      </Button>
    </Form>
        
    </div>
  )
}

export default Datadeletion
import React from 'react'
import NavBar from '../Components/NavBar'
import Footer from '../Components/Footer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser} from '@fortawesome/free-solid-svg-icons'
import { faPhone} from '@fortawesome/free-solid-svg-icons'
import { faBoxes} from '@fortawesome/free-solid-svg-icons'
import { faRunning} from '@fortawesome/free-solid-svg-icons'
import { faShippingFast} from '@fortawesome/free-solid-svg-icons'
import { faFingerprint} from '@fortawesome/free-solid-svg-icons'

//images
import shape13 from '../Images/dark-shape-13.png';
import shape14 from '../Images/dark-shape-09.png';
import shape12 from '../Images/shape-12.png';
import shape4 from '../Images/shape-04.png';
import imageFour from '../Images/logistics.jpg';
import aboutImage1 from '../Images/shape-38.png'
import aboutImage2 from '../Images/shape-44.png'
import aboutImage3 from '../Images/shape-37.png'
import shape3 from '../Images/shape-03.png'
import downImg1 from '../Images/downImg1.png'
import downImg2 from '../Images/downImg2.png'
import downImg3 from '../Images/downImg3.png'
import playStoreIcon from '../Images/app-store-icons-google-play.png'



function Home() {
  return (
    <div>

        <div className='hero'>
            <NavBar/>
            <div className='heroCont'>
                <div className='leftHero'>
                    <h1>Elevating Africa's <a className='aaone'>Logistics and</a> Supply Chain</h1>
                    <p>
                        Employing AI-driven automation for streamlined deliveries and supply chain while harnessing economies of scale to provide affordable pricing across Africa   
                        </p>
                    <a className='leftHeroBtn' href='https://play.google.com/store/apps/details?id=com.linkafrex.africa'><FontAwesomeIcon icon={faUser} className="laftIcon"/> Sign Up</a>
                </div>

                <div className='rightHero'>

                    <div className='contactCard'>
                        <div className='contactCardLeft'>
                            <FontAwesomeIcon icon={faPhone} className="contactIcon"/>
                        </div>
                        
                        <div>
                            <p>ONLINE SUPPORT</p>
                            <h4>0748027664</h4>

                        </div>
                    </div>

                    <img src={shape13} className='shapeOne' alt="" />
                    <img src={shape14} className='shapeTwo'  alt="" />
                    <img src={shape12} className='shapeThree'  alt="" />
                    <img src={shape4} className='shapeFour'  alt="" />

                    <div className='contOne'></div>
                    <div className='contTwo'></div>

                </div>
            </div>

           

        </div>

        <div className='features' id='features'>
            <div className='featuresTop'>
                <p>KARIBU</p>
                <h1>Welcome to Linkafrex</h1>
                <p>Join a Community of Businesses Benefiting from Linkafrex</p>
            </div>

            <div className='featuresCont'>
                <div className='featuresOne'>
                    <div className='featuresIconCont'>
                        <FontAwesomeIcon icon={faShippingFast} />
                    </div>
                    <h5 className='featuresH5'>Deliveries</h5>
                    <p className='featuresP'>
                        Get your packages delivered quickly and efficiently between cities. Whether it's documents or parcels, our intercity delivery service ensures your items reach their destination on time
                    </p>
                    <a href="https://play.google.com/store/apps/details?id=com.linkafrex.africa" className='downloadLinkBtn'>Download</a>
                </div>

                <div className='featuresFour'>
                    <div className='featuresIconCont2'>
                        <FontAwesomeIcon icon={faBoxes} />
                    </div>
                    <h5 className='featuresH5'>Cargo</h5>
                    <p className='featuresP'>
                    Move large goods and bulk shipments seamlessly across Africa. Our cargo service is designed to handle everything from industrial equipment to commercial goods, with a focus on safety and reliability.
                    </p>
                    <a href="https://play.google.com/store/apps/details?id=com.linkafrex.africa" className='downloadLinkBtn2'>Download</a>
                </div>

                <div className='featuresTwo'>
                <div className='featuresIconCont3'>
                        <FontAwesomeIcon icon={faRunning} /> 
                    </div>
                    <h5 className='featuresH5'>Errands</h5>
                    <p className='featuresP'>
                        Need something done within your city? Our errands service is here to help. From grocery shopping to picking up prescriptions, we handle your everyday tasks so you can focus on what matters most.
                    </p>
                    <a href="https://play.google.com/store/apps/details?id=com.linkafrex.africa" className='downloadLinkBtn3'>Download</a>
                </div>
                {/* <div className='featuresThree'>
                    <div className='featuresIconCont4'>
                        <FontAwesomeIcon icon={faCartShopping} />
                    </div>
                    <h5 className='featuresH5'>Inventory Management</h5>
                    <p className='featuresP'>Manage your inventory effortlessly with our free built-in feature, enabling you to organize, track, and deliver products hassle-free.</p>
                    <a href="" className='downloadLinkBtn4'>Download</a>
                </div> */}
            </div>
        </div>

        <div className='aboutSection'>
            <div className='aboutOne'>

                <img src={aboutImage1} alt="" className='aboutImg2'/>
                <img src={aboutImage3} alt="" className='aboutImg4' />
                <img src={aboutImage2} alt=""  className='aboutImg3' />
                <img src={imageFour} className='imageFour' alt="" />

                <div className='aboutDivOne'>

                    <img src={shape3} alt="" />
                    <h5>Door-to-Door Deliveries</h5>
                    <p>Door-to-Door Deliveries with Real-Time Tracking</p>

                </div>

                <div className='aboutDivTwo'>

                    <div className='contactCardLeft'>
                            <FontAwesomeIcon icon={faPhone} className="contactIcon"/>
                    </div>
                    <div>
                    <h5>3200</h5>
                    <p>Orders fulfilled</p>
                    </div>

                </div>

            </div>

            <div className='aboutTwo'>

                <h6>ABOUT US</h6>
                <h2>Streamline Your Supply Chain with Linkafrex</h2>
                <p className='aboutTwopara'>

                    Linkafrex is a cutting-edge delivery service based in Nairobi, dedicated to revolutionizing your business logistics. With a mission to provide fast, reliable, and efficient delivery solutions, we specialize in on-demand, next-day, and scheduled deliveries tailored to your specific needs.
                    <br/>
                    With our in-house team of skilled riders and a fleet of owned bikes, we guarantee a seamless and secure delivery process. To further enhance security, we've implemented SMS authentication. This feature allows businesses to provide a unique authentication code for riders to collect items, and customers to provide the same code for item handover.
                </p>

            </div>

        </div>

        <div className='sectionDownload'>

            <div className='downloadHeader'>
 
                <div className='downloadCard1'>
                    <h1 className='downH1'>1,500</h1>
                    <p>ACTIVE BUSINESSES</p>
                </div>
                <div className='downloadCard1'>
                    <h1 className='downH2'>30</h1>
                    <p>CLIENT SERVED</p>
                </div>
                <div className='downloadCard1'>
                    <h1 className='downH3'>1</h1>
                    <p>COUNTRIES</p>
                </div>
                <div className='downloadCard1'>
                    <h1 className='downH4'>2</h1>
                    <p>CITIES</p>
                </div>

            </div>

            <div className='downloadMid' id='download'>

                <img src={downImg1} className='downImg1' alt="" />
                <img src={downImg2} className='downImg2' lt="" />
               <div className='adhiambo'>
                <a href="https://play.google.com/store/apps/details?id=com.linkafrex.africa">
                <img src={playStoreIcon} className='playStoreIcon'  alt="" />
                </a>
               
               </div>
                <img src={downImg3} className='downImg3' alt="" />

            </div>

        </div>

    <Footer/>
       
    </div>
  )
}

export default Home
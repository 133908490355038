import React from 'react'
import logo from  '../Images/newlogo.png'
import {NavLink } from "react-router-dom";

import facebook from '../Images/facebook.svg'
import instagram from '../Images/instagram.png'
import linkedin from  '../Images/linkedin.svg'
import twitter from '../Images/twitter.svg'

function Footer() {
  return (
    <div className='footer'>

        <div className='footerCont1'>

        <div className='footerCont'>
            <img src={logo} className='footerLogo' alt="" />

            <p>
            Employing AI-driven automation for streamlined deliveries and supply chain while harnessing economies of scale to provide affordable pricing across Africa
                </p>


        </div>

        <div className='footerCont pagesFooter'> 

            <h3>Pages</h3>
            <p><NavLink to="/" className='footLinks'>Developers</NavLink></p>
            <p><NavLink to="/" className='footLinks'>Careers</NavLink></p>
            <p><NavLink to="/" className='footLinks'>Privacy Policy</NavLink></p>
            <p><NavLink to="/" className='footLinks'>Delete your Data</NavLink></p>   
            <p><NavLink to="/" className='footLinks'>Invest</NavLink></p>
            
        </div>

        <div className='footerCont'> 
            <h3>Contact</h3>
            <p>Kilimani, </p>
            <p>Nairobi</p> <br />
            <p>info@linkafrex.com</p>
            <p>0748027664</p>
        </div>

        {/* subs */}

        <div className='footerCont'>
            <h3>Subscribe</h3>
            <p>Enter your email address to register to our newsletter subscription</p>

            <div className='subsCont'>
                <input type="text" />
                <button>Subscribe</button>
            </div>

            <div className='socialCont'>
                <a href="https://www.instagram.com/linkafrex/"> <img src={instagram} className='socials' alt="" /></a>
                <a href="https://x.com/LinkaFrex"> <img src={twitter} className='socials'  alt="" /></a>
                <a href="https://www.linkedin.com/in/linka-frex-b526a4326/"> <img src={linkedin} className='socials'  alt="" /></a>
                <a href=" https://www.facebook.com/profile.php?id=61550358251871&mibextid=ZbWKwL"> <img src={facebook} className='socials'  alt="" /></a>
            </div>

        </div>
        </div>

        <hr />
        <div>
            <p className='copyrightText'>© 2024. Linkafrex LTD. All Rights Reserved.</p>
        </div>

       


    </div>
  )
}

export default Footer
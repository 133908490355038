import React from 'react'
import NavBar from '../Components/NavBar'
import Footer from '../Components/Footer'
import image1 from '../Images/googleicon.webp'
import image2 from '../Images/google-play-store-download-button-in-white-colors-download-on-the-google-play-store-free-png.webp'
import image3 from '../Images/shape-05.png'
import image4 from '../Images/downImg1.png'
import Accordion from 'react-bootstrap/Accordion';

function Download() {
  return (
    <div> <div className='mainCareer'>
    <NavBar/>
  </div>

  <div className='mainContentCareer'>

        <div className='downloadSec'>
            <h1 >Sign Up on Your Smartphone or Tablet</h1>

            <div className='downloadDiv'>
                <div>
                    <p>Click on the icon to download the app for your android phone or tablet</p>
                    <a href="https://play.google.com/store/apps/details?id=com.linkafrex.africa"><img src={image2} alt=""  className='downloadGoolgeIcon'/></a>
                </div>

            </div>



        

        </div>


  

  </div>
  <Footer/>
</div>
  )
}

export default Download
import React from 'react'

function PrivacyPolicy() {
  return (
    <div className='privacyPolicy'>

<h1>Privacy Policy</h1>
    <p>Last updated: [2nd Aug 2023]</p>

    <h2>1. Personal Information We Collect</h2>
    <p>We may collect and process the following personal information:</p>
    <ul>
        <li>Name</li>
        <li>Phone number</li>
        <li>Email address</li>
        <li>Geolocation data for journey details</li>
        <li>Payment information</li>
        <li>Information about disputes</li>
        <li>Device identification data for app usage</li>
    </ul>

    <h2>2. Purpose of Data Processing</h2>
    <p>We use the collected personal data for the following purposes:</p>
    <ul>
        <li>To efficiently connect customers with drivers for seamless urban transportation</li>
        <li>To display geolocation data and phone numbers to drivers for efficient pick-up</li>
        <li>To resolve quality issues related to transportation services</li>
        <li>To notify customers of updates to the Kiota Logistics app</li>
        <li>To analyze routes for geographic coverage and efficient route recommendations</li>
        <li>To communicate with customers using their name, phone number, and email</li>
        <li>To process payment on behalf of drivers for transportation services</li>
        <li>To store customer support data for dispute resolution and service quality purposes</li>
    </ul>

    <h2>3. Legal Basis</h2>
    <p>We process personal data to provide the service contracted with customers. Use of the Kiota Logistics app implies
        customers' agreement to the processing of identification and geolocation data.</p>
    <p>Personal data may also be processed based on legitimate interest grounds, such as investigating and detecting fraudulent payments.</p>

    <h2>4. Data Recipients</h2>
    <p>Passenger data is disclosed only to drivers who have activated the Kiota Logistics app, showing necessary information to
        enable pick-up and temporary communication.</p>
    <p>After providing the service, passenger contact details will remain visible to the driver for 24 hours to resolve any service-related issues.</p>
    <p>Feedback provided by customers is anonymous, and drivers do not receive the names and telephone numbers of customers who
        provide ratings and feedback.</p>
    <p>Personal data may be disclosed to Kiota Logistics group companies and partners based on the location of the passenger. Data
        processing by group companies and partners follows the conditions established in this privacy notice.</p>

    <h2>5. Security and Data Access</h2>
    <p>We ensure the security of personal data collected during service provision, stored in data centers located in European Union
        Member States. Only authorized employees have access to the data for issue resolution purposes.</p>
    <p>Geolocation data is processed in an anonymized form, linked to customers only when needed for dispute or fraud resolution.</p>
    <p>Data is used in an unidentified form (anonymized) for research and scientific purposes.</p>

    <h2>6. Data Access and Correction</h2>
    <p>customers can access and update their personal data via the Kiota Logistics app.</p>

    <h2>7. Data Retention</h2>
    <p>Personal data is stored as long as the passenger has an active account. After account closure, personal data will be deleted
        according to the policies set out in this section, unless retention is required for accounting, dispute resolution, or
        fraud prevention purposes.</p>
    <p>Financial data regarding transportation services provided to customers will be stored for 3 years after the last journey.</p>
    <p>Data required for accounting purposes will be stored for 7 years [after the last journey].</p>
    <p>Data will be stored for 10 years in cases of criminal offenses, fraud, or false information provided.</p>
    <p>In case of payment disputes, data will be retained until the claim is satisfied or the expiry date of such claims.</p>
    <p>Journey history data will be stored for 3 years, after which the data will be anonymized.</p>
    <p>Please note that the deinstallation of the Kiota Logistics app in a device does not delete personal data.</p>
    <p>If the Kiota Logistics app has not been used for 3 years, we will notify you to confirm if the account is still active. If
        there is no reply, the account will be closed, and personal data deleted unless retention is required for accounting,
        dispute resolution, or fraud prevention purposes.</p>

    <h2>8. Deletion</h2>
    <p>Personal data can be deleted only by deleting your account. Deleting your account will render the Kiota Logistics app
        inoperative for the deleted account.</p>
    <p>Deletion requests are processed within a month after receiving the request.</p>

    <h2>9. Data Portability</h2>
    <p>Personal data transfer requests are processed within a month after receiving the request. Upon verification of the customer, we will provide contact information, journey history for the last 3 years, and payment information.</p>

    <h2>10. Direct Marketing</h2>
    <p>We use email addresses and/or phone numbers for direct marketing purposes only if permission is given via the Kiota Logistics website or the app. Direct marketing messages may be personalized based on your use of Kiota Logistics services.</p>
    <p>If you no longer wish to receive direct marketing messages, click the "Unsubscribe" link in the footer of our emails or in the profile section of the Kiota Logistics app.</p>

    </div>
  )
}

export default PrivacyPolicy
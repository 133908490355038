import React, { useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight} from '@fortawesome/free-solid-svg-icons'
import { faBars} from '@fortawesome/free-solid-svg-icons'
import { faCircleXmark} from '@fortawesome/free-solid-svg-icons'
import logo from '../Images/newlogo.png';
import {useNavigate } from "react-router-dom";
import { Link } from 'react-scroll';
import {NavLink } from "react-router-dom";

function NavBar() {
  const [showProgress, setShowProgress] = useState(false);

  function openMobileMenu(){
    setShowProgress(true);
  }

  function closeMobileMenu(){
    setShowProgress(false);
  }

  return (
    <div className='generalNav'>
       <div className='navBar'>
          <NavLink to="/"><img src={logo}alt="" className='logo' /></NavLink>
            
            <div className='menuCont'>
                <ul className='menus'>
                    <li><NavLink to="/" className='navLinks'>Home</NavLink></li>
                    <li><Link to="features" smooth={true} duration={500} className='navLinks'>About</Link></li>
                    {/* <li><NavLink to="/" className='navLinks'>Solutions</NavLink></li> */}
                    <li><NavLink to="/download" className='navLinks'>Download</NavLink></li>
                    <li><NavLink to="/career" className='navLinks'>Careers</NavLink></li>
                    {/* <li><NavLink to="/developers" className='navLinks'>Developers</NavLink></li> */}
                    <a className='navSignInBtn' href='https://play.google.com/store/apps/details?id=com.linkafrex.africa'>Sign In <FontAwesomeIcon icon={faArrowRight} className="laftNavIcon"/></a>
                </ul>  
                <div className='mobileMenu' onClick={openMobileMenu}>
                  <FontAwesomeIcon icon={faBars} className="mobileMenuIcon"/>
                </div>    
            </div>
        </div>

        {showProgress && 

        <div className='mobileNav'>
          <div className='mobileNavTop'>
            <NavLink to="/"><img src={logo}alt="" className='logo' /></NavLink>

            <div className='closeMobileMenu' onClick={closeMobileMenu}>
                <FontAwesomeIcon icon={faCircleXmark} className="mobileMenuIcon2"/>
            </div>

          </div>

          <div className='menus2'>
              <li onClick={closeMobileMenu}><NavLink to="/" className='navLinks'>Home</NavLink></li>
              <li><Link onClick={closeMobileMenu} to="features" smooth={true} duration={500} className='navLinks'>About</Link></li>
              {/* <li><NavLink to="/" className='navLinks'>Solutions</NavLink></li> */}
              <li ><NavLink onClick={closeMobileMenu} to="/download" className='navLinks'>Download</NavLink></li>
              <li ><NavLink onClick={closeMobileMenu} to="/career" className='navLinks'>Careers</NavLink></li>
          </div> 
          <a className='navSignInBtn' href='https://play.google.com/store/apps/details?id=com.linkafrex.africa'>Sign In <FontAwesomeIcon icon={faArrowRight} className="laftNavIcon"/></a> 

        </div>
      }
    </div>
   
  )
}

export default NavBar